import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Filter as FilterInterface, SelectOption, PrimaryButtonStyle, FilterFields, Floor, CampaignStatusPrimark, StoreStatusPrimark, User } from "../interfaces/interfaces";
import { InputCheckBox, CloseButton, FormText, PrimaryButton, FormNumber, FormSelect, InputDate, InputSelectMultiple } from '.';
import { InputSelectSearchable } from './InputSelectSearchable';

export interface FilterProps {
    filter: FilterInterface,
    filterFields: FilterFields,
    countries?: SelectOption[],
    languages?: SelectOption[],
    textile_types?: SelectOption[],
    support_types?: SelectOption[],
    support_statuses?: SelectOption[],
    stores?: SelectOption[],
    sections?: SelectOption[],
    floors?: Floor[],
    countryManagers?: User[],
    opening?: Boolean,
    
    onClose: () => void,
    onClear: () => void,
    onSubmit: (filter: FilterInterface) => void
}

interface State {
    filter: FilterInterface,
}

type FilterPropsSum = FilterProps & WithTranslation;

class FilterComponent extends React.Component<FilterPropsSum, State> {

    constructor(props: FilterPropsSum) {
        super(props);

        this.state = {
            filter: props.filter,
        };
    }

    componentDidMount() {
        document.body.classList.add('stopScroll')
    }

    componentWillUnmount() {
        document.body.classList.remove('stopScroll')
    }

    onSubmit() {
        const { filter } = this.state;
        this.props.onSubmit(filter);
    }

    getFilterFields() {
        const { filterFields } = this.props;
        return filterFields.split(" ");
    }

    renderSearch() {
        const { t } = this.props;
        const { filter } = this.state;

        if (this.getFilterFields().includes('search')) {
            return (
                <div className="form-row">
                    <input id="search" name="search" className="field field--search" type="text" placeholder={t('name_status_placeholder')} value={filter.search} onChange={(event) => this.setState({ filter: { ...filter, search: event.target.value }})} />
                </div>
            );
        }
    }
    renderSearchSupport() {
        const { t } = this.props;
        const { filter } = this.state;

        if (this.getFilterFields().includes('search_support')) {
            return (
                <div className="form-row">
                    <input id="search" name="search" className="field field--search" type="text" placeholder={t('point')} value={filter.search_support} onChange={(event) => this.setState({ filter: { ...filter, search_support: event.target.value }})} />
                </div>
            );
        }
    }

    renderAddress() {
        const { t } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('address')) {
            return (
                <div className="form-row">
                    <FormText id="address" label={ t('address') } value={filter.address} onChange={(text) => this.setState({ filter: { ...filter, address: text } })} />
                </div>
            );
        }
    }
    
    renderStoreManager() {
        const { t } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('store_manager')) {
            return (
                <div className="form-row">
                    <FormText id="store-manager" label={ t('store_manager') } value={filter.storeManager} onChange={(text) => this.setState({ filter: { ...filter, storeManager: text } })} />
                </div>
            );
        }
    }

    renderCountryManager() {
        const { t, countryManagers } = this.props;
        const { filter } = this.state;

        if (this.getFilterFields().includes('country_managers')) {
        const countryManagerOptions = countryManagers!.map(value => ({ value: ""+value.id, name: `${value.name} ${value.last_name}`, stores: value.stores }))
            return (
                <div className="form-row">
                <article className="form-item">
                    <label>{ t('country_manager') }</label>
                    <InputSelectMultiple 
                        id="country_managers" 
                        selected={filter?.country_managers?.map(elem => elem.name) || []} 
                        options={countryManagerOptions} 
                        onChange={(options) => this.setState({ filter: { ...filter, country_managers: options.map(option => {
                                return {name: option.value, stores: countryManagerOptions.find(element => element.value === option.value)?.stores || []}
                            }
                        )}})}
                        placeholder={ t('country_manager') } 
                        helpText={ "" }
                        disabled={ false }/>
                </article>
            </div>
            );
        }
    }

    renderCustomerShopNo() {
        const { t } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('customer_shop_no')) {
            return (
                <div>
                    <FormText id="customer_shop_no" label={ t('customer_shop_no') } value={filter.customer_shop_no} onChange={(text) => this.setState({ filter: { ...filter, customer_shop_no: text } })} />
                </div>
            );
        }
    }

    renderCountry() {
        const { t, countries } = this.props;
        const { filter } = this.state;
        const sortedCountries = countries?.sort((n1,n2) => {
            if (n1.name > n2.name) {
                return 1;
            }
        
            if (n1.name < n2.name) {
                return -1;
            }
        
            return 0;
        });

        if (this.getFilterFields().includes('country')) {
            return (
                <div className="form-row">
                                <article className="form-item">
                                    <label>{ t('country') }</label>
                                    <InputSelectMultiple 
                                        id="countries" 
                                        selected={filter?.countries || []} 
                                        options={sortedCountries!} 
                                        onChange={(options) => this.setState({ filter: { ...filter, countries: options.map(option => option.value)}})}
                                        placeholder={ t('country') } 
                                        helpText={ "" }
                                        disabled={ false }/>
                                </article>
                            </div>
            )
        }
    }

    renderDepartment() {
        const { t } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('department')) {
            return (
                <FormText id="department" label={ t('department') } value={filter.department} onChange={(text) => this.setState({ filter: { ...filter, department: text } })} />
            );
        }
    }

     renderLanguages() {
        const { t, languages } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('languages')) {
            return (
                <FormSelect id="language" label={ t('language') } options={ languages } value={filter.language} onChange={(value, label) => this.setState({ filter: { ...filter, language: value } })} />
            );
        }
    }

    renderLanguage() {
        const { t, languages } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('language')) {
            return (
                <FormSelect id="language" label={ t('language') } options={ languages } value={filter.language} onChange={(value, label) => this.setState({ filter: { ...filter, language: value } })} />
            );
        }
    }

    renderKenduProcessing() {
        const { t} = this.props;
        const { filter } = this.state;
        let kendu_options=[{value:'ADAPTATION',name:t('adaptation')},{value:'SPECIAL_REQUEST',name:t('Special request')},{value:'DIRECTORY',name:t('Directory')}]
        if (this.getFilterFields().includes('kendu_processing')) {
           
            return (
                <div className="form-row">
                                <article className="form-item">
                                    <label>{ t('kendu_processing') }</label>
                                    <InputSelectMultiple 
                                        id="kendu_processing" 
                                        selected={filter?.kendu_processing || []} 
                                        options={kendu_options||[]} 
                                        onChange={(options) => this.setState({ filter: { ...filter, kendu_processing: options.map(option => option.value)}})}
                                        placeholder={ '' } 
                                        helpText={ "" }
                                        disabled={ false }/>
                                </article>
                            </div>
            )
        }
    }
    

    

    renderSupportStatus() {
        const { t, support_statuses } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('support_status')) {
            return (
                <InputSelectSearchable id="support_status" label={ t('support_status') } options={support_statuses} value={filter.support_status} onChange={(value) => this.setState({ filter: { ...filter, support_status: value } })} />
                // <FormSelect id="support_status" label={ t('support_status') } options={ support_statuses } value={filter.support_status} onChange={(value, label) => this.setState({ filter: { ...filter, support_status: value } })} />
            );
        }
    }
    
    renderStoreOpening() {
        const { filter } = this.state;
        if (this.getFilterFields().includes('opening')) {
            return (
                <InputCheckBox
                   
                    name={ "opening" }
                    id={"opening"}
                    checked={ filter.opening || false }
                    checkBoxChange={(checked) => {
                        if (checked) {
                            this.setState({ filter: { ...filter, opening: true } });
                        } else {
                            this.setState({ filter: { ...filter, opening: false } });
                        }
                    }}
                />
                // <FormSelect id="support_status" label={ t('support_status') } options={ support_statuses } value={filter.support_status} onChange={(value, label) => this.setState({ filter: { ...filter, support_status: value } })} />
            );
        }
    }

    renderSupportStatusMultiple() {
        const { t, support_statuses } = this.props;
        const { filter } = this.state;
        return (
            <div className="form-row">
                            <article className="form-item">
                                <label>{ t('support_status') }</label>
                                <InputSelectMultiple 
                                    id="support_statuses" 
                                    selected={filter?.support_statuses || []} 
                                    options={support_statuses!} 
                                    onChange={(options) => this.setState({ filter: { ...filter, support_statuses: options.map(option => option.value)}})}
                                    placeholder={ t('support_status') } 
                                    helpText={ "" }
                                    disabled={ false }/>
                            </article>
                        </div>
        )
        
    }

    renderDate() {
        const { t } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('date')) {
            return (
                <fieldset className="formlines-container">
                    <div className="form-row form-row col-d-1-1">
                        <article className="form-item">
                            <label>{ t('start_date') }</label>
                        </article>
                        <article className="form-item">
                            <label>{ t('end_date') }</label>
                        </article>
                    </div>
                    <div className="form-row form-row">
                        <InputDate start_date={filter.start_date} end_date={filter.end_date} onChange={(start_date, end_date) => this.setState({ filter: { ...filter, start_date: start_date, end_date: end_date } })} />
                    </div>
                </fieldset>
            );
        }
    }

    renderStatus() {
        const { t } = this.props;

        const { filter } = this.state;
        if (this.getFilterFields().includes('status')) {
            return (
                <fieldset>
                    <div className="form-row check-group-title">
                        <legend>{ t('status') }</legend>
                    </div>
                    <div className="check-group-row col-2">
                        {Object.values(CampaignStatusPrimark).map(status => {

                            var statusString = status.toLowerCase()
                           
                            if (statusString === "kendu_processing") {
                                statusString = t("ratio_processing")
                            } else {
                                statusString = t(statusString)
                            }
                            
                            return (<InputCheckBox
                                key={status}
                                name={ t(statusString) }
                                id={status+"_status"}
                                checked={ (filter.campaignStatuses || []).includes(status) }
                                checkBoxChange={(checked) => {
                                    if (checked) {
                                        this.setState({ filter: { ...filter, campaignStatuses: [...filter.campaignStatuses || [], status] } });
                                    } else {
                                        this.setState({ filter: { ...filter, campaignStatuses: (filter.campaignStatuses || []).filter(element => element !== status) } });
                                    }
                                }}
                            />)
                        }
                            
                        )}
                    </div>
                </fieldset>
            );
        }
    }

    renderPrimarkStatus() {
        const { t } = this.props;

        const { filter } = this.state;
        if (this.getFilterFields().includes('primark_status')) {
            return (
                <fieldset>
                    <div className="form-row check-group-title">
                        <legend>{ t('status') }</legend>
                    </div>
                    <div className="check-group-row col-2">
                        {Object.values(StoreStatusPrimark).map(status =>
                            <InputCheckBox
                                key={status}
                                name={ t(status.toLowerCase()) }
                                id={status+"_status"}
                                checked={ (filter.primark_statuses || []).includes(status) }
                                checkBoxChange={(checked) => {
                                    if (checked) {
                                        this.setState({ filter: { ...filter, primark_statuses: [...filter.primark_statuses || [], status] } });
                                    } else {
                                        this.setState({ filter: { ...filter, primark_statuses: (filter.primark_statuses || []).filter(element => element !== status) } });
                                    }
                                }}
                            />
                        )}
                    </div>
                </fieldset>
            );
        }
    }

    renderTextileType() {
        const { t, textile_types } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('textile_type')) {

            return (
                <div className="form-row">
                                <article className="form-item">
                                    <label>{ t('textile') }</label>
                                    <InputSelectMultiple 
                                        id="support_types" 
                                        selected={filter?.textileTypesList || []} 
                                        options={textile_types||[]} 
                                        onChange={(options) => this.setState({ filter: { ...filter, textileTypesList: options.map(option => option.value)}})}
                                        placeholder={ '' } 
                                        helpText={ "" }
                                        disabled={ false }/>
                                </article>
                            </div>
            )
        }
    }

    renderSupportType() {
        const { t, support_types} = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('support_type')) {

            return (
                <div className="form-row">
                                <article className="form-item">
                                    <label>{ t('support_type') }</label>
                                    <InputSelectMultiple 
                                        id="support_types" 
                                        selected={filter?.supportTypesList || []} 
                                        options={support_types||[]} 
                                        onChange={(options) => this.setState({ filter: { ...filter, supportTypesList: options.map(option => option.value)}})}
                                        placeholder={ '' } 
                                        helpText={ "" }
                                        disabled={ false }/>
                                </article>
                            </div>
            )
        }
    }

    renderFloor() {
        const { t, floors } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('floor') && filter.store) {
            const floorOptions = floors?.filter(floor => floor.store_no.toString() === filter.store?.toString()).map(floor => ({ value: floor.entry_no.toString(), name: floor.name }));
            return (
                <InputSelectSearchable id="floor" label={ t('floor') } options={floorOptions} value={filter.floor} onChange={(value) => this.setState({ filter: { ...filter, floor: value } })} />
            );
        }
    }

    renderPriority() {
        const { t } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('priority')) {
            return (
                <FormText id="priority" label={ t('priority') } value={filter.priority} onChange={(text) => this.setState({ filter: { ...filter, priority: text } })} />
            );
        }
    }

    renderStore() {
        const { t, stores } = this.props;
        const { filter } = this.state;
        
        if (this.getFilterFields().includes('store')) {
            return (
                <InputSelectSearchable id="store" label={ t('store') } options={stores} value={filter.store} onChange={(value) => this.setState({ filter: { ...filter, store: value, floor: undefined } })} />
            );
        }
    }

    renderSection() {
        const { t, sections } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('section')) {
            return (

                <InputSelectSearchable id="section" label={ t('section') } options={sections} value={filter.section} onChange={(value) => this.setState({ filter: { ...filter, section: value } })} />
            );
        }
    }

    renderWidth() {
        const { t } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('width')) {
            return (
                <FormNumber id="width" label={ t('width') } value={filter.width} unitClass="mm" onChange={(text) => this.setState({ filter: { ...filter, width: parseInt(text) } })} />
            );
        }
    }
    
    renderHeight() {
        const { t } = this.props;
        const { filter } = this.state;
        if (this.getFilterFields().includes('height')) {
            return (
                <FormNumber id="height" label={ t('height') } value={filter.height} unitClass="mm" onChange={(text) => this.setState({ filter: { ...filter, height: parseInt(text) } })} />
            );
        }
    }

    render () {
        const { t } = this.props;
        return (
            <section id="popUp-advanced-filter" className="cover-blur advanced-filter-popup active">
                <section className="popup">
                    <section className="popup-top">
                        <h2>{ t('advanced_filter') }</h2>
                        <CloseButton onClick={this.props.onClose} />
                    </section>
                    <section className="popup-content formlines-container">
                        <fieldset className="advanced-filter-datapicker">
                            {this.renderSearch()}
                            {this.renderSearchSupport()}
                            {this.renderDate()}
                            {this.renderAddress()}
                            {this.renderStoreManager()}
                            {this.renderCountryManager()}
                            
                            {this.renderCustomerShopNo()}


                            {(this.getFilterFields().includes('support_status')) &&
                                <div className="form-row">
                                    {this.renderSupportStatus()}
                                </div>
                            }

                           

                            {(this.getFilterFields().includes('support_status_multiple')) &&
                                <div className="form-row">
                                    {this.renderSupportStatusMultiple()}
                                </div>
                            }
                            
                            {(this.getFilterFields().includes('store') || this.getFilterFields().includes('floor')) &&
                                <div className={`${ (this.getFilterFields().includes('store') && this.getFilterFields().includes('floor'))? 'form-row':'form-row col-d-1-1'}`}>
                                    {this.renderStore()}
                                    {this.renderFloor()}
                                </div>
                            }
                            {(this.getFilterFields().includes('width') || this.getFilterFields().includes('height')) &&
                                <div className="form-row col-d-1-1">
                                    {this.renderWidth()}
                                    {this.renderHeight()}
                                </div>
                            }

                            {(this.getFilterFields().includes('section')) && 
                                <div className="form-row options-in-top">
                                    {this.renderSection()}
                                </div>
                            }

                            {(this.getFilterFields().includes('priority')) &&
                                <div className="form-row options-in-top">
                                    {this.renderPriority()}
                                </div>
                            }
                            {(this.getFilterFields().includes('kendu_processing')) &&
                                <div className="form-row options-in-top">
                                    {this.renderKenduProcessing()}
                                </div>
                            }
                            
                            {(this.getFilterFields().includes('country') || this.getFilterFields().includes('department')) &&
                                <>
                                    {this.renderCountry()}
                                    {this.renderDepartment()}
                                </>
                            }

                            {(this.getFilterFields().includes('languages')) &&
                                <div className="form-row">
                                  {this.renderLanguages()}
                                </div>
                            }
                            
                        
                            {this.renderSupportType()}
                            {this.renderTextileType()}

                            {(this.getFilterFields().includes('language')) &&
                                <>
                                    <div className="form-row">
                                        <legend>{ t('Section and priority') }</legend>
                                    </div>
                                    {this.renderLanguage()}
                                   
                                </>
                            }

                            {(this.getFilterFields().includes('opening')) &&
                                <>
                                    <div className="form-row">
                                        <legend>{ t('Opening') }</legend>
                                    </div>
                                    {this.renderStoreOpening()}
                                   
                                </>
                            }
                            
                        </fieldset>
                        {this.renderPrimarkStatus()}
                        {this.renderStatus()}
                    </section>
                    <section className="popup-actions">
                        <PrimaryButton onClick={this.props.onClear} style={PrimaryButtonStyle.OUTLINED}>
                            { t('clear_filters') }
                        </PrimaryButton>
                        <PrimaryButton onClick={() => this.onSubmit()} style={PrimaryButtonStyle.FILLED}>
                            { t('apply_filter') }
                        </PrimaryButton>
                    </section>
                </section>
            </section>
        );
    };
}

const Filter = withTranslation()(FilterComponent);
export { Filter };
